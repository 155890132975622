<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="card">
        <DataTable :value="generalapprovalsetlist" :lazy="true" v-model:selection="selectedData" selectionMode="single"
            :scrollable="true" stripedRows :scrollHeight="sHeight" :loading="loading" scrollDirection="both"
            :paginator="true" :rows="50" :totalRecords="totalRecords" :first="first"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3" @page="listPage($event)">
            <template #header>
                <div class="grid">
                    <div class="col-8">
                        <div class="text-left">
                            <InputText id='searchUserNameInput' placeholder="审批人" v-model="searchUserName"
                                type="text" />&nbsp;&nbsp;&nbsp;&nbsp;
                            <CalfInputDropdown placeholder="审批线" v-model:selectedValue="searchIdentification"
                                v-model:selectedTxt="searchIdentificationName" id="searchidentificationDrop"
                                dictObjName="deptdict" :loadAll="true" />&nbsp;&nbsp;&nbsp;&nbsp;
                            <Dropdown showClear="true" placeholder="类别" v-model="searchCategoryId"
                                id="searchCategoryIdDrop" :options="dicts['categoryDict']" optionLabel="name"
                                optionValue="code" />
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="text-right">
                            <Button label="查询" icon="pi pi-search" @click="loadData(1)"
                                class="ml-auto" />&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button label="新建" icon="pi pi-plus" @click="createdetail()"
                                class="ml-auto" />&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button label="删除" icon="pi pi-times" @click="deldetail()" class="button-danger" />
                        </div>
                    </div>
                </div>
            </template>
            <Column field="category" header="类别" style="width: 10%" frozen>
                <template #body="slotProps">
                    <Button :label="slotProps.data.category" @click="opendetail(slotProps.data)"
                        class="p-button-link" />
                </template>
            </Column>
            <Column field="identificationname" header="审批线" style="width: 30%"></Column>
            <Column field="level" header="审批级别" style="width: 20%">
                <template #body="slotProps">
                    {{formatDict('levelDict',slotProps.data['level'])}}
                </template>
            </Column>
            <Column field="upperlimit" header="上限条件" style="width: 10%">
                <template #body="slotProps">
                    {{formatCurrency(slotProps.data.upperlimit)}}
                </template></Column>
            <Column field="lowerlimit" header="下限条件" style="width: 10%">
                <template #body="slotProps">
                    {{formatCurrency(slotProps.data.lowerlimit)}}
                </template>
            </Column>
            <Column field="username" header="审批人" style="width: 20%"></Column>
        </DataTable>
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" v-model:visible="displayMaximizable" :style="{width: '70vw'}" :maximizable="true"
        :modal="true">
        <div class="card p-fluid">
            <h5>基本信息</h5>
            <div class="field">
                <label for="categoryid">类别</label>
                <Dropdown showClear="true" v-model="currentRow.categoryid" id="categoryid"
                    :options="dicts['categoryDict']" optionLabel="name" optionValue="code" />
            </div>
            <div class="field">
                <label for="identification">审批线</label>
                <CalfInputDropdown v-model:selectedValue="currentRow.identification"
                    v-model:selectedTxt="currentRow.identificationname" :canEditable="false" id="identification"
                    :loadAll="true" dictObjName="deptdict" />
            </div>
            <div class="field">
                <label for="level">审批级别</label>
                <Dropdown showClear="true" v-model="currentRow.level" id="level" :options="dicts['levelDict']"
                    optionLabel="name" optionValue="code" />
            </div>
            <div class="field">
                <label for="userid">上限条件</label>
                <InputNumber id="locale-user" v-model="currentRow.upperlimit" mode="decimal" :minFractionDigits="2" />
            </div>
            <div class="field">
                <label for="userid">下限条件</label>
                <InputNumber id="locale-user" v-model="currentRow.lowerlimit" mode="decimal" :minFractionDigits="2" />
            </div>
            <div class="field">
                <label for="userid">审批人</label>
                <CalfInputDropdown v-model:selectedValue="currentRow.userid" v-model:selectedTxt="currentRow.username"
                    id="userid" dictObjName="userdict" :loadAll="true" />
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable" />
                <Button label="保存" icon="pi pi-check" @click="submitData" :loading="butLoading" autofocus />
            </div>
        </template>
    </Dialog>
</template>
<script>
    import {
        ref,
    } from 'vue';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import requests from '../../service/newaxios';
    import * as Base64 from 'js-base64';
    import CommonJs from '../../js/Common.js';
    import MessageTip from '../../components/Message';
    import CalfInputDropdown from '../../components/CalfInputDropdown.vue';

    export default {
        setup() {
            const confirm = useConfirm();
            const formatCurrency = (value) => {
                if (value) {
                    var res = value.toString().replace(/\d+/, function (n) { // 先提取整数部分
                        return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
                            return $1 + ",";
                        });
                    })
                    return res;
                } else {
                    return '0';
                }
            };
            return {
                confirm,
                formatCurrency,
            };
        },
        data() {
            return {
                selectedData: ref(),
                searchCategoryId: ref(),
                searchUserName: ref(),
                searchIdentification: ref(),
                searchIdentificationName: ref(),
                currentRow: null,
                loading: false,
                butLoading: false,
                displayMaximizable: false,
                recordsubject: '',
                totalRecords: 0,
                first: 0,
                generalapprovalsetlist: [],
                dicts: {
                    levelDict: [{
                        name: '一级审批',
                        code: 1
                    }, {
                        name: '二级审批',
                        code: 2
                    }, {
                        name: '三级审批',
                        code: 3
                    }, {
                        name: '四级审批',
                        code: 4
                    }, {
                        name: '五级审批',
                        code: 5
                    }, {
                        name: '六级审批',
                        code: 6
                    }],
                    categoryDict: [],
                },
                sHeight: '650px',
            }
        },
        created() {
            MessageTip.initMsg();
            this.sHeight = (window.innerHeight - 300) + "px";
            this.loadDict();
            this.loadData(1);
        },
        methods: {
            loadData(v_page) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: [{
                        name: 'categoryid',
                        value: this.searchCategoryId,
                        operation: '',
                    }, {
                        name: 'identification',
                        value: this.searchIdentification,
                        operation: '',
                    }, {
                        name: 'username',
                        value: this.searchUserName,
                        operation: '',
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{generalapprovalsetlist(where:" + JSON.stringify(listwhere) +
                    "){id identification identificationname categoryid category userid username level upperlimit lowerlimit}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.generalapprovalsetlist = jsonData.data.generalapprovalsetlist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = 50 * (jsonData.page.pageindex - 1);
                        this.loading = false;
                    }
                });
            },
            loadDict() {
                var dictlistwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'Categories',
                        value: 'ApprovalSetCategory',
                        operation: '',
                    }]
                };
                if (dictlistwhere.conditions.length > 0) {
                    var graphql = '{dictionarylist(where:' + JSON.stringify(dictlistwhere) +
                        '){name details {name code}}}';
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        graphql
                    ).then(res => {
                        if (res.errcode == "0") {
                            var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                            console.log(dataObj);
                            if (dataObj.dictionarylist.length > 0) {
                                this.dicts['categoryDict'] = dataObj.dictionarylist[0].details;
                            }
                        } else {
                            MessageTip.warnmsg('字典数据加载失败');
                        }
                    });
                }
            },
            listPage(event) {
                var page = event.page;
                console.log(page);
                this.loadData(page + 1);
            },
            formatDict(v_dictName, value) {
                var name = value;
                var isFind = false;
                if (this.dicts[v_dictName]) {
                    for (var i = 0; i < this.dicts[v_dictName].length; i++) {
                        if (this.dicts[v_dictName][i].code == value) {
                            name = this.dicts[v_dictName][i].name;
                            isFind = true;
                            break;
                        }
                    }
                }
                if (isFind) {
                    return name;
                } else {
                    return '';
                }
            },
            opendetail(v_row) {
                this.currentRow = v_row;
                this.recordsubject = v_row.identificationname + v_row.category + this.formatDict('levelDict', v_row
                    .level);
                this.displayMaximizable = true;
            },
            createdetail() {
                var row = {
                    id: '-1',
                    identification: '',
                    identificationname: '',
                    categoryid: '',
                    category: '',
                    userid: '',
                    username: '',
                    level: 1,
                    upperlimit: 0,
                    lowerlimit: 0,
                };
                this.currentRow = row;
                this.recordsubject = '新建';
                this.displayMaximizable = true;
            },
            deldetail() {
                console.log(this.selectedData);
                if (this.selectedData['id']) {
                    this.loading = true;
                    var id = this.selectedData['id'];
                    var title = this.selectedData['identificationname'] + this.selectedData['category'] + this
                        .formatDict('levelDict', this.selectedData['level']);
                    this.confirm.require({
                        message: '确认要删除' + title + '?',
                        header: '确认',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: '删除',
                        rejectLabel: '放弃',
                        accept: () => {
                            requests.graphql_requests.post(
                                '/graphql/?appkey=' + sessionStorage.getItem(
                                    "appkey"),
                                'delete{generalapprovalset(o:' + JSON.stringify(this
                                    .selectedData) +
                                '){id}}'
                            ).then(res => {
                                if (res.errcode == "0") {
                                    for (var i = 0; i < this.generalapprovalsetlist.length; i++) {
                                        if (this.generalapprovalsetlist[i]['id'] == id) {
                                            this.generalapprovalsetlist.splice(i, 1);
                                            break;
                                        }
                                    }
                                    if (this.totalRecords > 0) {
                                        this.totalRecords = this.totalRecords - 1;
                                    }
                                    this.loading = false;
                                    MessageTip.successmsg('删除成功');
                                }
                            });
                        },
                        reject: () => {
                            this.loading = false;
                        }
                    });
                }
            },
            closeMaximizable() {
                this.closeButLoading();
                this.displayMaximizable = false;
            },
            submitData() {
                if (this.currentRow) {
                    if (this.currentRow.identification == '' || parseInt(this.currentRow.identification) <= 0) {
                        MessageTip.warnmsg('请选择审批线');
                        return;
                    }
                    if (this.currentRow.userid == '' || parseInt(this.currentRow.userid) <= 0) {
                        MessageTip.warnmsg('请选择审批人');
                        return;
                    }
                    this.butLoading = true;
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem(
                            "appkey"),
                        'mutation{generalapprovalset(o:' + JSON.stringify(this
                            .currentRow) +
                        '){id}}'
                    ).then(res => {
                        if (res.errcode == "0") {
                            this.currentRow = null;
                            this.loadData(1);
                            this.closeMaximizable();
                            MessageTip.successmsg('保存成功');
                        } else {
                            this.closeButLoading();
                        }
                    });
                }
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
        },
        components: {
            CalfInputDropdown,
        }
    }
</script>